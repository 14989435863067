import React from "react";
import ComponentSitemap from "../../Shared/ComponentSitemap/ComponentSitemap";
import * as styles from "./section-sitemap.module.scss";
import {
  useSiteQuery,
  useDictionaryQuery,
  useLocale,
} from "../../../hooks";
import { useSearchQuery } from "../../../hooks/layout/useSearchQuery";

const SectionSitemap = ({ data }) => {
  const {
    wellbeing,
    WorldOfTea,
    Sustainability,
    Products,
    helpCenter,
    Purpose,
    WorldOfTeaArticles,
    WellbeingArticles,
    SustainabilityArticles,
    Recipes,
  } = data;

  const { sitemapDirectLink, sitemapTitle } = useSiteQuery();
  const locale = useLocale();
  const {
    helpCenterHeading,
    wellbeingHeading,
    worldOfTeaHeading,
    ourTeasHeading,
    recipesHeading,
    sustainabilityHeading,
  } = useDictionaryQuery();

  const { purposeHeading } = useSearchQuery();

  const conditionLocales = [
    "es-PE",
    "en-CA",
    "fr-CA",
    "en-US",
    "nl-BE",
    "fr-BE",
    "fr",
    "it",
    "en-AU",
  ];

  const shouldUseCategoryPage = (locale) => {
    return conditionLocales.includes(locale);
  };

  return (
    <div>
      <div className={styles.sitemapContainer}>
        <h1 className={styles.sitemapTitle}>{sitemapTitle}</h1>
        <div className={styles.sitemap}>
          {/* { locale !== "en-PK" && locale !== "es-PE" ? <ComponentSitemap title="PREFERENCE-CENTRE" items={null} /> : ""} */}
          <ComponentSitemap title={ourTeasHeading} items={Products} />
          {shouldUseCategoryPage(locale) ? (
            <ComponentSitemap
              title={worldOfTeaHeading}
              items={WorldOfTeaArticles}
              categoryPage={true}
            />
          ) : (
            <ComponentSitemap title={worldOfTeaHeading} items={WorldOfTea} />
          )}
          {shouldUseCategoryPage(locale) ? (
            <ComponentSitemap
              title={sustainabilityHeading}
              items={SustainabilityArticles}
              categoryPage={true}
            />
          ) : (
            <ComponentSitemap
              title={sustainabilityHeading}
              items={Sustainability}
            />
          )}
          {shouldUseCategoryPage(locale) ? (
            <ComponentSitemap
              title={wellbeingHeading}
              items={WellbeingArticles}
              categoryPage={true}
            />
          ) : (
            <ComponentSitemap title={wellbeingHeading} items={wellbeing} />
          )}
          <ComponentSitemap title={recipesHeading} items={Recipes} />
          {/* {helpCenterHeading && (
            <ComponentSitemap title={helpCenterHeading} items={helpCenter} />
          )} */}
          {locale !== "en-PK" &&
            !conditionLocales.includes(locale) && (
              <ComponentSitemap title={purposeHeading} items={Purpose} />
            )}
          {sitemapDirectLink?.map(({ title, slug, target }, index) => {
            const linking = slug ? slug : (target && target.slug);
            return (
              <ComponentSitemap
                key={index}
                title={title}
                link={linking}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionSitemap;
