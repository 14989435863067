import React from "react"

import * as styles from "./component-sitemap.module.scss"
import { Link } from "gatsby"
import { useLocalPath, useLocale, useUrl } from "../../../hooks"
const ComponentSitemap = ({
  title,
  items,
  link,
  categoryPage = false,
}: {
  title
  items?
  link?
  categoryPage?
}) => {
  const baseUrl = useUrl()
  const locale = useLocale()

  if (categoryPage) {
    let list = []
    items?.edges[0]?.node?.sections?.map(section => {
      if (section && section.type === "componentGridItems") {
        list = section.sectionThumbnail
      }
    })
    {
      (locale === "en-AE" || locale === "ar-AE") &&
        items?.edges[0]?.node?.sections?.map(section => {
          if (section && section.type === "Articles") {
            list = section.articles
          }
        })
    }

    return (
      <div className={styles.sectionSitemap}>
        <h2 className={styles.sectionSitemapTitle}>{title}</h2>
        <ul className={styles.sectionSitemapList}>
          {list.map((item: any, index: number) => {
            // Removing the slash
            let slug = item.slug
            if (!slug) return
            let text = slug
            if (text.includes("produkte/")) {
              text = text.split("produkte/")[1]
            } else if (text.includes("products/")) {
              text = text.split("products/")[1]
            }

            return (
              <li
                className={`${styles.sectionSitemapList__item} ${styles.sectionSitemapHover}`}
                key={index}
              >
                <Link to={useLocalPath(`/${slug}`)}>
                  {item.title ? item.title : title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  if (items) {
    return (
      <div className={styles.sectionSitemap}>
        <h2 className={styles.sectionSitemapTitle}>{title}</h2>
        <ul className={styles.sectionSitemapList}>
          {items?.edges?.map((item: any, index: number) => {
            // Removing the slash
            let slug = item.node.path
              ? item.node.path.substring(1)
              : item.node.slug
            if (!slug) return
            let text = slug
            if (text.includes("produkte/")) {
              text = text.split("produkte/")[1]
            } else if (text.includes("products/")) {
              text = text.split("products/")[1]
            }

            return (
              <li
                className={`${styles.sectionSitemapList__item} ${styles.sectionSitemapHover}`}
                key={index}
              >
                <Link to={useLocalPath(`/${slug}`)}>
                  {item.node.title ? item.node.title : title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  } else {
    return (
      <div className={styles.sectionSitemap}>
        <Link to={useLocalPath(`/${link}`)}>
          <h2 className={styles.sectionSitemapTitle}>{title}</h2>
        </Link>
      </div>
    )
  }
}

export default ComponentSitemap
