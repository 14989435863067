import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Seo from "../components/seoGlobal";
import SectionSitemap from "../components/sections/SectionSitemap/SectionSitemap";
import SectionSignUp from "../components/sections/section-signup/SectionSignUp";
import Schema from "../components/schema";
import { useSearchQuery } from "../hooks/layout/useSearchQuery";

const PageSitemap = ({
    location,
    pageContext,
}) => {
    const {sitemapSeoTitle, sitemapSeoDescription} = useSearchQuery()
    const dataQuery = useStaticQuery(
        graphql`
            query{
                WorldOfTeaArticles:allContentfulPageGeneric(filter: {title: {eq: "World Of Tea"}}) {
                    edges {
                        node {
                            node_locale
                            sections {
                                ... on ContentfulComponentFeaturedItems {
                                    type
                                    sectionThumbnail {
                                        ... on ContentfulPageArticle {
                                            title
                                            slug
                                        }
                                    }
                                }
                                ... on ContentfulComponentRelatedProductsArticles {
                                    type
                                    articles {
                                      slug
                                      title
                                    }
                                  }
                            }
                        }
                    }
                }
                WellbeingArticles:allContentfulPageGeneric(filter: {title: {eq: "Wellbeing Page"}}) {
                    edges {
                        node {
                            node_locale
                            sections {
                                ... on ContentfulComponentFeaturedItems {
                                    type
                                    sectionThumbnail {
                                        ... on ContentfulPageArticle {
                                            title
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                SustainabilityArticles:allContentfulPageGeneric(filter: {title: {eq: "Sustainability Page"}}) {
                    edges {
                        node {
                            node_locale
                            sections {
                                ... on ContentfulComponentFeaturedItems {
                                    type
                                    sectionThumbnail {
                                        ... on ContentfulPageArticle {
                                            title
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                wellbeing: allContentfulPageArticle(filter: {pageType: {eq: "Wellbeing"}}) {
                    edges {
                        node {
                            node_locale
                            title
                            slug
                        }
                    }
                }
                WorldOfTea: allContentfulPageArticle(filter: {pageType: {eq: "WorldOfTea"}}) {
                    edges {
                        node {
                            node_locale
                            title
                            slug
                        }
                    }
                }
                Sustainability: allContentfulPageArticle(filter: {pageType: {eq: "Sustainability"}}) {
                    edges {
                        node {
                            node_locale
                            title
                            slug
                        }
                    }
                }
                Recipes: allContentfulPageRecipe {
                    edges {
                        node {
                            node_locale
                            slug
                            title
                        }
                    }
                }
                Products: allContentfulPageProducts {
                    edges {
                        node {
                            node_locale
                            title
                            slug
                        }
                    }
                }
                helpCenter:   allContentfulPageHelpCenter {
                    edges {
                        node {
                            seoTitle
                            slug
                            node_locale
                        }
                    }
                }
                Purpose: allContentfulPageArticle(filter: {pageType: {eq: "Purpose"}}) {
                    edges {
                        node {
                            node_locale
                            title
                            slug
                        }
                    }
                }
            }
        `
    )
    for (const key in dataQuery) {
        dataQuery[key].edges = dataQuery[key].edges.filter((edge) => edge.node.node_locale === pageContext.node_locale);
    }
    return (
        <>
            <Seo
                lang={pageContext.node_locale}
                title={sitemapSeoTitle}
                description={sitemapSeoDescription.sitemapSeoDescription}
            />
            <Schema />
            <SectionSitemap data={dataQuery} />
        </>
    )
}

export default PageSitemap